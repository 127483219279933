import React from 'react';
import Table from 'react-bootstrap/Table';

const LastActiveSubscriptionTab = () => {

  return (
    <div>
      <Table 
        striped
        bordered 
        hover
        responsive="md"
      >
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Amount (in USD)</th>
            <th>Product Renewal Type</th>
            <th>Product Renewal On</th>
            <th>Credits/Limits Renewal On</th>
            {/* <th>Username</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LastActiveSubscriptionTab;
