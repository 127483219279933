import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import Overview from '../components/overview/index';
import EmailVerifier from '../components/email_verifier/index';
import EmailFinder from '../components/email_finder/index';
import Prospect from '../components/prospect/index';

const AnalyticsSidebar = () => {
  const [activeTab, setActiveTab] = useState('overview');

  const handleTabs = (key) => {
    setActiveTab(key)
  }

  return (
    <Tab.Container
      defaultActiveKey="overview"
      activeKey={activeTab}
      onSelect={handleTabs}
    >
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            <Nav.Item>
              <Nav.Link eventKey="overview">Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="email_verifier">Email Verifier</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="email_finder">Email Finder</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="prospect">Prospect</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content className='fs-4'>
            <Tab.Pane eventKey="overview">{activeTab === 'overview' && <Overview />}</Tab.Pane>
            <Tab.Pane eventKey="email_verifier">{activeTab === 'email_verifier' && <EmailVerifier />}</Tab.Pane>
            <Tab.Pane eventKey="email_finder">{activeTab === 'email_finder' && <EmailFinder />}</Tab.Pane>
            <Tab.Pane eventKey="prospect">{activeTab === 'prospect' && <Prospect />}</Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default AnalyticsSidebar;
