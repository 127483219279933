import React from 'react'

const SubsHistoryTabs = () => {
  return (
    <div>
      SubsHistory table
    </div>
  )
}

export default SubsHistoryTabs
