import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const TablePagination = ({ currentPageIndex, totalpage, updateTableData }) => {
  const handlePrevClick = () => {
    if (currentPageIndex > 0) {
      updateTableData(currentPageIndex - 1);
    }
  };

  const handlePageItemClick = (pageIndex) => {
    updateTableData(pageIndex);
  };

  const handleNextClick = () => {
    if (currentPageIndex < totalpage - 1 || currentPageIndex === totalpage - 1) {
      updateTableData(currentPageIndex + 1);
    }
  };

  const handleFirstPageClick = () => {
    updateTableData(0);
  };

  const handleLastPageClick = () => {
    updateTableData(totalpage - 1);
  };

  // Disabling pagination if total page is less than 1 or equal to 1
  if (totalpage <= 1) {
    return null;
  }

  return (
    <Pagination>
      <Pagination.First onClick={handleFirstPageClick} />
      <Pagination.Prev onClick={handlePrevClick} />
      {Array.from({ length: totalpage }, (_, i) => (
        <Pagination.Item
          key={i}
          active={i === currentPageIndex}
          onClick={() => handlePageItemClick(i)}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={handleNextClick} />
      <Pagination.Last onClick={handleLastPageClick} />
    </Pagination>
  );
};

export default TablePagination;
