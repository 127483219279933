import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import MarkAsPaid from '../widgets/mark_as_paid_modal';
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'


const initialState = {
  invoiceData: []
}

const InvoicesAndReceiptsTable = ({ invoices, data, getUserInfo, updateReceiptcomp, client }) => {
  const [state, setState] = useState(initialState)
  const jwtToken = useSelector((state) => state.user.jwtToken)
  const userName = useSelector(state => state.user.name);
  const id = useSelector((state) => state.user.id);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, invoiceData: invoices || [] }))
  }, [invoices]);

  const getTable = () => {
    const table = IndividualUserConstants.RECEIPT_TABLE_COLUMNS[client]
    return (
      <Table
        striped
        hover
        className="p-1 bg-white table-responsive receipt-table"
      >
        <thead>
          <tr>
            {table.map((opts, idx) => (
              <th key={idx}>
                <span className="mulitline-header">
                  {opts.header}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {state.invoiceData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {table.map((opts, colIndex) => {
                const accessorValue = _.get(rowData, opts.accessorKey);
                return (
                  <td key={colIndex}>
                    {UserInfoFormatters[opts.formatter]({
                      client,
                      value: accessorValue,
                      rowData,
                      userData: data,
                      jwtToken,
                      userName,
                      id
                    })}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const getBillingAddress = () => {
    let address = data.address

    if (_.isEmpty(address))
      return null

    return (
      <div className='d-flex flex-column'>
        <h6 className='fw-bold'>Billing Address</h6>
        <span>{_.startCase(address.name)}</span>
        <span>{address.line1}, {address.line2}</span>
        <span>{address.country.label} - {address.postal_code}</span>
        {
          address.taxId
            ?
            <span>
              {
                address.country.label.toLowerCase() === 'india'
                  ? <>GST: </>
                  : <>Tax: </>
              }
              <span>{address.taxId}</span>
            </span>
            : null
        }
      </div>
    )
  }

  return (
    <div className='invoice-recept-tab'>
      <div className='d-flex justify-content-between'>
        {getBillingAddress()}
        <div className='text-end flex-grow-1 align-self-end'>
          {_.includes(IndividualUserConstants.INVOICE_AND_RECEIPT_TAB[client].SHOW_BUTTONS, 'generate_receipt') &&
            data.activated_on && data.status === 'active' ?
            <MarkAsPaid
              data={data}
              brand="clearout"
              getUserInfo={getUserInfo}
              updateReceiptcomp={updateReceiptcomp}
              client={client}
            />
            : null
          }
        </div>
      </div>
      {getTable()}
    </div>
  );
};

export default InvoicesAndReceiptsTable;
