import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'

const initialState = {
  showModal: false,
  selectedOption: 'app',
  isApiCallInProgress: false,
  quickRender: false
}

function LogoutSessionModal({ userData, getUserInfo, client }) {
  const [state, setState] = useState(initialState);
  const userName = useSelector(state => state.user.name);

  useEffect(() => {
    if (state.quickRender) {
      setState((prevState) => ({
        ...prevState,
        quickRender: false
      }))
    }
  }, [state.quickRender])

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }

    let requestBody = {
      co_user_id: userData.user_id,
      cop_user_id: userData.user_id,
      session_type: state.selectedOption,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName
      },
    }

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].LOGOUT_SESSIONS.API_BODY)

    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].LOGOUT_SESSIONS.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          getUserInfo();
          handleCloseModal()
          showToastNotification({
            message: 'Successfully logged out of all sessions',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  const handleSessionSelect = (event) => {
    const { value } = event.target
    setState((prevState) => ({
      ...prevState,
      selectedOption: value,
      quickRender: true
    }))
  }

  return (
    <>
      <p
        onClick={handleShowModal}
        className='m-0'
      >
        Logout Sessions
      </p>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            {userData?.name}&apos;s sessions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-6'>
                  <Form.Label className='w-100 '>Logout session from :</Form.Label>
                </div>
                <div className='col-md-6 align-items-center d-flex h-100'>
                  <div className="mb-3">
                    <Form.Check
                      inline
                      label='APP'
                      type='radio'
                      value='app'
                      onChange={handleSessionSelect}
                      checked={state.selectedOption === 'app'}
                    />
                    <Form.Check
                      inline
                      label='API'
                      type='radio'
                      value='api'
                      onChange={handleSessionSelect}
                      checked={state.selectedOption === 'api'}
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress || !state.selectedOption}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogoutSessionModal;
