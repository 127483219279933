import React from 'react'
import _ from 'lodash';
import { useSelector } from 'react-redux';

import ApiService from '../../helpers/apiservice';
import showToastNotification from '../widgets/toastify';
import * as Constants from '../../helpers/constants';
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'

const ForgotPasswordLink = ({ userData, client }) => {
  const userName = useSelector(state => state.user.name);
  const id = useSelector((state) => state.user.id);

  function onGetForgotPasswordLink() {
    const requestBody = {
      co_user_id: userData.user_id,
      user_id: userData.user_id,
      action_type: 'forgot_password',
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName
      },
      crm_user_id: id
    };

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].FORGOT_PASSWORD_LINK.API_BODY)
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].FORGOT_PASSWORD_LINK.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Password Link copied unsuccessfully',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          navigator.clipboard.writeText(response.data.reset_url)
          showToastNotification({
            message: 'Password Link copied successfully',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <div>
      <p
        onClick={onGetForgotPasswordLink}
        className='m-0'
      >
        Get Forgot Password Link
      </p>
    </div>
  )
}

export default ForgotPasswordLink