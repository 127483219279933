import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import _ from 'lodash'

import ApiService from '../../helpers/apiservice'
import * as Constants from '../../helpers/constants'
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'
import LastActiveSubscriptionTab from './last_active_subs_tab'
import SubsHistoryTabs from './subs_history_tab'
import InvoicesAndReceiptsTable from './invoices_receipts'
import Analytics from '../analytics';
import workinProgress from '../widgets/work_in_progress_toastify'
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'


const initialState = {
  isUpdateApiRateModalOpen: false,
  isRemoveApiRateModalOpen: false,
  isDailyLimitModalOpen: false,
  isRemoveDailyLimitModalOpen: false,
  isUpdateDailyLimitModalOpen: false,
  invoices: [],
  isUpdateReceiptComp: false,
  key: 'Credits & Limits',
  keys: 'Last Active Subscription'
}

const TabsInfo = ({ data, getUserInfo, client }) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (state.key === 'Invoices & Receipts') {
      let requestBody = {
        co_user_id: data.user_id,
        cop_user_id: data.user_id,
        client_secret: Constants.CLIENT_SECRET,
      }
      let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].RECEIPTS.API_BODY)
      ApiService[UserInfoPageApiHelpers.API_HELPERS[client].RECEIPTS.API_NAME](body)
        .then((response) => {
          if (response.status === Constants.CO_API.SUCCESS) {
            setState(prevState => ({ ...prevState, invoices: response.data }))
          }
        })
    }
  }, [data.user_id, state.isUpdateReceiptComp, state.key, client])

  useEffect(() => {
    if (state.key === 'Plans' || state.key === 'Plans & Subscriptions') {
      workinProgress();
    }
  }, [state.key]);

  const updateReceiptcomp = () => {
    setState(prevState => ({ ...prevState, isUpdateReceiptComp: !state.isUpdateReceiptComp }))
  }

  const handleTabSelect = (key) => {
    setState((prevState) => ({ ...prevState, key: key }));
  };

  const getCreditsSection = () => {
    const creditsSection = IndividualUserConstants.CREDITS_LIMITS_TAB[client].CREDITS_SECTION

    return (
      <div className="container overflow-hidden">
        {creditsSection.map((opts, idx) => {
          const accessorValue = _.get(data, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex justify-content-between'
            >
              <p>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: data,
                getUserInfo
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const getLimitsSection = () => {
    const limitsSection = IndividualUserConstants.CREDITS_LIMITS_TAB[client].LIMITS_SECTION

    return (
      <div className="container overflow-hidden">
        {limitsSection.map((opts, idx) => {
          const accessorValue = _.get(data, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex justify-content-between'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: data,
                getUserInfo,
                label: opts.label
              })}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Tabs
        activeKey={state.key}
        onSelect={handleTabSelect}
        className="mb-3"
        fill
      >
        {_.includes(IndividualUserConstants.TAB_SECTION[client], 'credits_limits') &&
          <Tab
            eventKey="Credits & Limits"
            title="Credits & Limits"
          >
            <div
              className="container overflow-hidden"
            >
              <div className="row gx-5">
                <div className="col">
                  <div className="p-2 border credits-limits-section h-100">
                    <h5 className='text-center'>Credits</h5>
                    <div className='d-flex justify-content-between'>
                      {getCreditsSection()}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border credits-limits-section h-100">
                    <h5 className='text-center'>Limits</h5>
                    <div className='d-flex justify-content-between'>
                      {getLimitsSection()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        }
        {_.includes(IndividualUserConstants.TAB_SECTION[client], 'plans') &&
          <Tab
            eventKey="Plans"
            title="Plans"
          >
            <Tabs
              id="controlled-tab-example"
              activeKey={state.keys}
              onSelect={handleTabSelect}
              className="mb-3"
              fill
            >
              <Tab
                eventKey="Last Active Subscription"
                title="Last Active Subscription"
              >
                <LastActiveSubscriptionTab />
              </Tab>
              <Tab
                eventKey="Subscription History"
                title="Subscription History"

              >
                <SubsHistoryTabs />
              </Tab>
            </Tabs>
          </Tab>
        }
        {_.includes(IndividualUserConstants.TAB_SECTION[client], 'subscriptions') &&
          <Tab
            eventKey="Plans & Subscriptions"
            title="Subscriptions"
          >
            <Tabs
              id="controlled-tab-example"
              activeKey={state.keys}
              onSelect={handleTabSelect}
              className="mb-3"
              fill
            >
              <Tab
                eventKey="Last Active Subscription"
                title="Last Active Subscription"
              >
                <LastActiveSubscriptionTab />
              </Tab>
              <Tab
                eventKey="Subscription History"
                title="Subscription History"

              >
                <SubsHistoryTabs />
              </Tab>
            </Tabs>
          </Tab>
        }
        {_.includes(IndividualUserConstants.TAB_SECTION[client], 'invoices_receipts') &&
          data.user_role === 'owner' &&
          <Tab
            eventKey="Invoices & Receipts"
            title="Invoices & Receipts"
          >
            <InvoicesAndReceiptsTable
              invoices={state.invoices}
              data={data}
              getUserInfo={getUserInfo}
              updateReceiptcomp={updateReceiptcomp}
              client={client}
            />
          </Tab>
        }
        {_.includes(IndividualUserConstants.TAB_SECTION[client], 'analytics') &&
          <Tab
            eventKey="Stats"
            title="Analytics"
          >
            {state.key === 'Stats' && <Analytics />}
          </Tab>
        }
      </Tabs>
    </>
  )
}

export default TabsInfo
