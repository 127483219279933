import React from 'react';
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomOverlayTrigger from '../../../widgets/overlay_trigger'
import workinProgress from '../../../widgets/work_in_progress_toastify'
import UserModal from '../../../users_list/user_stats_modal'
import ClipBoardCopy from '../../../widgets/clipboard_copy'
import * as Helpers from '../../../../helpers/helper'
import * as Constants from '../../../../helpers/constants'


class TableFormatters {

  /* Common formatters */
  srNoFormater = (cell) => (
    <div className="text-center">
      {cell.row.index + 1}
    </div>
  )

  creditsFormatter = (cell) => (
    <div className="d-flex px-2 credits">
      {this.#creditsFormat(cell.getValue())}
    </div>
  )

  recurFormatter = (cell) => (
    <div className="d-flex recur">
      {this.#recurFormat(cell.getValue())}
    </div>
  )

  subsFormatter = (cell) => (
    <div className="subscription">
      {this.#subscriptionFormat(cell.getValue())}
    </div>
  )

  thresHoldFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#thresHoldFormat(cell.row.original)}
    </div>
  )

  userRoleFormatter = (cell) => (
    <div className="px-2">{this.#roleFormat(cell.getValue())}</div>
  )

  userSourceFormatter = (cell) => (
    <div className="px-2">{cell.getValue()}</div>
  )

  channelSourceFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#channelSourceFormat(cell.getValue())}
    </div>
  )

  channelSourceCampaignFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#channelSourcecampaignFormat(cell.getValue())}
    </div>
  )

  channelSourceQueryFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#channelqueryFormat(cell.getValue())}
    </div>
  )

  affiliateFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#affiliateFormat(cell.getValue())}
    </div>
  )

  signUpIPFormatter = (cell) => (
    <div className="px-2">{cell.getValue()}</div>
  )

  signUpCountryFormatter = (cell) => (
    <div className="px-2">{cell.getValue()}</div>
  )

  activatedOnFormatter = (cell) => (
    <div
      className="d-flex px-2 activated_on"
    >
      {this.#joinedOnTimeFormat(cell.getValue())}
    </div>
  )

  createdOnFormatter = (cell) => (
    <div
      className="d-flex px-2 created_on"
    >
      {cell.getValue() ? (
        <span>
          {Helpers.formatDate(cell.getValue(), 'DD-MMM-YYYY @ h:mm:ss a')}
        </span>
      ) : (
        <span>-</span>
      )}
    </div>
  )

  userIdFormatter = (cell) => (
    <div className="px-2">{cell.getValue()}</div>
  )

  /* Co Formatters */
  coNameFormatter = (cell) => (
    <div>
      <div className="py-2">
        <CustomOverlayTrigger
          content={this.#getProjectStatus(cell.row.original)}
          placement="top"
        >
          <FontAwesomeIcon
            icon={cell.row.original.user_type === 'organization' ? 'fa-solid fa-users' : 'fa-solid fa-user'}
            style={{ color: this.#getIconColor(cell.row.original) }}
          />
        </CustomOverlayTrigger>
        {cell.row.original.name ? (
          <span className="ps-2">
            <CustomOverlayTrigger
              content={cell.row.original.name}
              placement="top"
            >
              <Link
                to={`/dashboard/clearout_individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                {cell.row.original.name}
              </Link>
            </CustomOverlayTrigger>
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
      <div
        className="d-flex justify-content-end paid-tag"
      >
        <div className="px-5">
          {this.#getPaidTag(cell.row.original) ? (
            <Badge
              bg="success"
            >
              Paid
            </Badge>
          ) : null}
        </div>
        <div className="px-2">
          <UserModal
            cell={cell}
            selectedUser={cell.row.original}
          />
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`click here to get the activities of ${cell.getValue()}`}
            placement="bottom"
          >
            <Link to='#'>
              <FontAwesomeIcon
                icon="fa-brands fa-wpexplorer"
                onClick={workinProgress}
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to view the matomo activity logs of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link to='#'>
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-up-right-from-square"
                onClick={workinProgress}
              />
            </Link>

          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the email verifier lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link to='#'>
              <FontAwesomeIcon
                icon="fa-solid fa-sheet-plastic"
                onClick={workinProgress}
              />
            </Link>

          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the email finder lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link to='#'>
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
                onClick={workinProgress}
              />
            </Link>

          </CustomOverlayTrigger>

        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the prospect lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link to='#'>
              <FontAwesomeIcon
                icon="fa-solid fa-rectangle-list"
                onClick={workinProgress}
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
      </div>
    </div>
  )

  coEmailFormatter = (cell) => {
    const name = cell.row.original.name
    const email = cell.getValue()
    return (
      <div className="d-flex justify-content-between px-2 email-cell">
        {name ? (
          <span>{email}</span>
        ) : (
          <span>
            <Link
              to={`/dashboard/clearout_individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
              className="text-decoration-none"
            >
              {email}
            </Link>
          </span>
        )}
        <ClipBoardCopy textToCopy={email} />
      </div>
    )
  }


  coPhoneNumberFormatter = (cell) => (
    <div
      className="d-flex justify-content-between px-2 phone-cell"
    >
      {cell.getValue() ? (
        <span>{cell.getValue()}</span>
      ) : (
        <span>-</span>
      )}
      {cell.getValue() ? (
        <ClipBoardCopy textToCopy={cell.getValue()} />
      ) : null}
    </div>
  )

  coActivatedByFormatter = (cell) => (
    <div
      className="px-2"
    >
      {cell.getValue() ? (
        Helpers.capitalizeFirstLetter(cell.getValue())
      ) : '-'}
    </div>
  )


  coSignUpFormatter = (cell) => (
    <div className="d-flex">
      {this.#ssoFormat(cell.getValue())}
    </div>
  )

  coLastPurchasedFormatter = (cell) => (
    <div className="d-flex px-2 last_purchased">
      {this.#lastPurchasedOnFormat(cell.getValue())}
    </div>
  )

  coTotalAmountPaidFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#formatTotalAmountPaid(cell.getValue())}
    </div>
  )

  coPaymentCountFormatter = (cell) => (
    <div className="d-flex px-2">
      {this.#totalPaidCount(cell.getValue())}
    </div>
  )

  coAccountDeleteReasonFormat = (cell) => {
    return (
      <div>
        {this.#accountDeleteReasonFormat(cell.getValue())}
      </div>
    );
  };

  /* Private functions */

  #getProjectStatus = (row) => {
    if (row.account_status === 'removed') {
      return 'Removed'
    } else if (!row.activated_on || row.activated_on === 'Yet to Activate') {
      return 'Yet to activate'
    } else if (row.status === 'active') {
      return 'Active'
    } else {
      return 'Inactive'
    }
  }

  #getIconColor = (row) => {
    if (row.account_status === 'removed') {
      return '#ff6767'
    } else if (!row.activated_on || row.activated_on === 'Yet to Activate') {
      return '#a3a3a3'
    } else if (row.status === 'active') {
      return '#6229e3'
    } else {
      return '#e48725'
    }
  }

  #getPaidTag(user) {
    let isPaid = false
    if (user.tags && user.tags.length > 0) {
      let paidTag = user.tags.find((tag) => tag.type === 'paid')
      isPaid = paidTag && paidTag.is_paid
    }
    return isPaid
  }

  #creditsFormat = (cell) => {
    return (
      <div className="credits-format">
        {cell.available !== null ? (
          <p
            className="mb-0"
            title="Remaining credits"
          >
            Remaining credits : {Helpers.isNumber(cell.total) ? Number(cell.total).toLocaleString() : '-'}
          </p>
        ) : (
          'Remaining credits : Unlimited'
        )}
      </div>
    )
  }

  #ssoFormat = (cell) => {
    if (cell.value && cell.value.signup_source) {
      let ssoDetails = Helpers.capitalizeFirstLetter(cell.value.signup_source) + ' / ' + Helpers.capitalizeFirstLetter(cell.value.signup_method)
      return <div>{ssoDetails}</div>
    } else {
      return 'Direct / Email'
    }
  }

  #recurFormat = (cell) => {
    if (cell && cell.credits) {
      let data = Helpers.getRecurTable(cell)
      return data
    } else {
      return '-'
    }
  }

  #subscriptionFormat = (cell) => {
    if (cell && cell.status) {
      let data = Helpers.getSubsTable(cell)
      return data
    } else {
      return '-'
    }
  }

  #thresHoldFormat = (row) => {
    let data = '-'
    let thresholdSign = ''
    if (row.settings?.low_credit_balance) {
      data = row.settings?.low_credit_balance.min_threshold

      if (data >= row.credits?.available) {
        thresholdSign = 'red'
      }
    }
    return <span style={{ color: thresholdSign }}>{data}</span>
  }

  #roleFormat = (cell) => {
    let data = cell === 'analyst' ? 'Executive' : cell
    return data ? Helpers.capitalizeFirstLetter(data) : '-'
  }

  #channelSourceFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelSource(cell)}</div>
    } else {
      return '-'
    }
  }

  #channelSourcecampaignFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelSourceCampaign(cell)}</div>
    } else {
      return '-'
    }
  }

  #channelqueryFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelQuery(cell)}</div>
    } else {
      return '-'
    }
  }

  #affiliateFormat = (cell) => {
    return cell ? cell : '-'
  }

  #lastPurchasedOnFormat = (cell) => {
    if (cell && cell.length > 0 && cell[0].is_paid) {
      return Helpers.formatDate(cell[0].last_purchased_on, 'DD-MMM-YYYY @ h:mm:ss a')
    } else {
      return '-'
    }
  }

  #formatTotalAmountPaid = (tags) => {
    if (tags && tags.length > 0) {
      const totalPaid = tags[0].total
      return Number(totalPaid).toLocaleString('en-US', { currency: 'USD' })
    } else {
      return '0.00'
    }
  }

  #totalPaidCount = (cell) => {
    if (cell && cell.length > 0) {
      return cell[0].count
    } else {
      return 0
    }
  }

  #joinedOnTimeFormat = (cell) => {
    let date
    if (cell && cell !== 'Yet to Activate') {
      date = Helpers.formatDate(cell, 'DD-MMM-YYYY @ h:mm:ss a')
    } else {
      date = 'Yet to Activate'
    }
    return <span title={date}>{date}</span>
  }

  #accountDeleteReasonFormat = (cell) => {
    let reason;
    if (cell) {
      reason = Constants.ACCOUNT_DELETE_REASONS[cell]
      return (
        <div>
          {reason || Helpers.capitalizeFirstLetter(cell)}
        </div>
      );
    } else {
      return (
        <div>-</div>
      );
    }
  };

  /* Cop Formatters */
  copNameFormatter = (cell) => (
    <div>
      <div className="py-2">
        <CustomOverlayTrigger
          content={this.#getProjectStatus(cell.row.original)}
          placement="top"
        >
          <FontAwesomeIcon
            icon={cell.row.original.user_type === 'organization' ? 'fa-solid fa-users' : 'fa-solid fa-user'}
            style={{ color: this.#getIconColor(cell.row.original) }}
          />
        </CustomOverlayTrigger>
        {cell.row.original.name ? (
          <span className="ps-2">
            <CustomOverlayTrigger
              content={cell.row.original.name}
              placement="top"
            >
              <Link
                to={`/dashboard/clearoutphone_individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                {cell.row.original.name}
              </Link>
            </CustomOverlayTrigger>
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
      <div className="d-flex">
        <div className="paid-tag">
          {this.#getPaidTag(cell.row.original) ? (
            <Badge
              bg="success badge-outline"
            >
              Paid
            </Badge>
          ) : null}
        </div>
        <div
          className="d-flex"
        >
          <div className="px-2">
            <CustomOverlayTrigger
              content={`click here to get the activities of ${cell.getValue()}`}
              placement="bottom"
            >
              <Link to='#'>
                <FontAwesomeIcon
                  icon="fa-brands fa-wpexplorer"
                  onClick={workinProgress}
                />
              </Link>
            </CustomOverlayTrigger>
          </div>
          <div className="px-2">
            <CustomOverlayTrigger
              content={`Click here to view the matomo activity logs of ${cell.row.original.name}`}
              placement="bottom"
            >
              <Link to='#'>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-up-right-from-square"
                  onClick={workinProgress}
                />
              </Link>

            </CustomOverlayTrigger>
          </div>
          <div className="px-2">
            <CustomOverlayTrigger
              content={`Click here to get the Phone lists of ${cell.row.original.name}`}
              placement="bottom"
            >
              <Link to='#'>
                <FontAwesomeIcon
                  icon="fa-solid fa-rectangle-list"
                  onClick={workinProgress}
                />
              </Link>
            </CustomOverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  )

  copEmailFormatter = (cell) => {
    const name = cell.row.original.name
    const email = cell.getValue()
    return (
      <div className="d-flex justify-content-between px-2 email-cell">
        {name ? (
          <span>{email}</span>
        ) : (
          <span>
            <Link
              to={`/dashboard/clearoutphone_individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
              className="text-decoration-none"
            >
              {email}
            </Link>
          </span>
        )}
        <ClipBoardCopy textToCopy={email} />
      </div>
    )
  }

}


export default new TableFormatters()