import React, { useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import AbuseListTable from './abuse_list_table';
import * as UserInfoPageApiHelpers from '../individual_user_info/helpers/user_info_apis'
import FiltersComponent from '../widgets/filters';
import * as FiltersConstants from '../widgets/filters/constants'

import '../../static/css/userlist.css'

const initialState = {
  abuse_list: { nodes: [] },
  tableDataLength: 1,
  searchText: '',
  sort: {
    'updatedAt': 'desc'
  },
  filters: {
    date_range: {
      filter_label: 'date_range',
      value: {
        key: 'created_on',
        preset: 'ps_last_7_days_including_today'
      }
    },
    task: {
      filter_label: 'Action',
      label: 'All',
      value: null
    }
  },
}

const filterParams = (filters) => {
  let queryFilter = {
    task: filters.task?.value || null,
    name: filters.name?.value || '',
    date_range: filters.date_range?.value || null,
  }
  if (queryFilter.status === 'all') {
    queryFilter.status = null
  }
  return queryFilter
}
const AbuseLogs = ({ client }) => {
  const [state, setState] = useState(initialState);
  const getAbuseList = useCallback((skip, limit, searchText) => {
    let requestBody = {
      skip: skip,
      limit: limit,
      search: searchText,
      sort: state.sort,
      filters: { ...filterParams(state.filters) },
      client_secret: Constants.CLIENT_SECRET,
    };
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].ABUSE_LOGS.API_BODY);
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].ABUSE_LOGS.API_NAME](body)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          const updatedData = res.data.data.map(v => {
            v.id = v.id || _.uniqueId('abuse_');
            return v;
          });

          setState(prevState => ({
            ...prevState,
            abuse_list: { nodes: updatedData },
            tableDataLength: updatedData.length
          }));
        } else if (res.status === Constants.CO_API.FAILED) {
          console.log('Failed to get abuse logs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.filters, state.sort, client]);

  const onSearch = (event) => {
    const newSearchText = event.target.value;
    setState(prevState => ({ ...prevState, searchText: newSearchText }));
    getAbuseList(0, 50, newSearchText, { created_on: 'desc' });
  };

  const clearSearch = () => {
    setState(prevState => ({ ...prevState, searchText: '' }))
    getAbuseList(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
  }

  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME

  return (
    <Container fluid >
      <h1 className='header'>{clientDisplayName} Abuse Logs</h1>
      <FiltersComponent
        filterState={state.filters}
        onSearch={onSearch}
        clearSearch={clearSearch}
        searchValue={state.searchText}
        onSetFilters={onSetFilter}
        filters={FiltersConstants.ABUSE_LOGS[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ABUSE_LOGS}
        resetFilters={FiltersConstants.ABUSE_LOGS[client].onResetFilters}
        skipFilter={['date_range', 'org_filter']}
        client={client}
      />
      <div className='pt-3'>
        <AbuseListTable
          getAbuseList={getAbuseList}
          data={state.abuse_list}
          client={client}
          setState={setState}
        />
      </div>
    </Container>
  )
}

export default AbuseLogs
