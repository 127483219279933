
export const FILTERS_TO_INCLUDE = {
  clearout: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  clearoutphone: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']

  },
}

export const USER_LIST_PAGE = {
  clearout: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  clearoutphone: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  }
}

export const ABUSE_LOGS = {
  clearout: {
    filters: [
      {
        name: 'Action',
        value: 'task',
        menus: [
          { label: 'All', value: 'signup_abuse' },
          { label: 'Signup Abuse', value: 'signup_abuse' },
          { label: 'Signup Activation Abuse', value: 'signup_activation_abuse' },
          { label: 'Email Edit Abuse', value: 'email_edit_abuse' },
        ],
      },
      {
        name: 'Type',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Email Denied', value: 'email_denied' },
          { label: 'Matomo Blocked', value: 'matomo_blocked' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Captcha Blocked', value: 'captcha_blocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
  clearoutphone: {
    filters: [
      {
        name: 'Action',
        value: 'task',
        menus: [
          { label: 'All', value: 'signup_abuse' },
          { label: 'Signup Abuse', value: 'signup_abuse' },
          { label: 'Signup Activation Abuse', value: 'signup_activation_abuse' },
          { label: 'Email Edit Abuse', value: 'email_edit_abuse' },
        ],
      },
      {
        name: 'Type',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Email Denied', value: 'email_denied' },
          { label: 'Matomo Blocked', value: 'matomo_blocked' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Captcha Blocked', value: 'captcha_blocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
}

export const ABUSE_UPDATE_HISTORY = {
  clearout: {
    filters: [
      {
        name: 'Tasks',
        value: 'task',
        menus: [
          { label: 'All', value: null },
          { label: 'Abuse Limiter Updates', value: 'abuse_limiter_updated' },
          { label: 'Abuse List Updates', value: 'abuse_list_updated' },
        ],
      },
      {
        name: 'Name',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Country Unblocked', value: 'country_unblocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today',
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
  clearoutphone: {
    filters: [
      {
        name: 'Tasks',
        value: 'task',
        menus: [
          { label: 'All', value: null },
          { label: 'Abuse Limiter Updates', value: 'abuse_limiter_updated' },
          { label: 'Abuse List Updates', value: 'abuse_list_updated' },
        ],
      },
      {
        name: 'Name',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Country Unblocked', value: 'country_unblocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today',
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
}

export const ANALYTICS = {
  clearout: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  }
}