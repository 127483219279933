import React, { Profiler } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import store from './store';
import router from './router'
import { onRender } from './helpers/profiler';
import { ToastContainer } from 'react-toastify';

import './index.css'
import './static/css/custom.css'
import './static/css/root.css'


const root = ReactDOM.createRoot(document.getElementById('root'), { identifierPrefix: 'cocrm-' });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Profiler
        id='root'
        onRender={onRender}
      >
        <RouterProvider router={router} />
      </Profiler>
    </Provider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
