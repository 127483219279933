import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'

const initialState = {
  reason: 'User Requested',
  showModal: false,
  userRunningFiles: [],
  isApiCallInProgress: false
};

function DeleteUserModel({ userData, getUserInfo, client }) {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }
  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    let requestBody = {
      co_user_id: id,
      cop_user_id: userData.user_id,
      user_id: userData.user_id,
      crm_user_id: id,
      reason: state.reason,
      client_secret: Constants.CLIENT_SECRET,
    };

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].DELETE_ACCOUNT.API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].DELETE_ACCOUNT.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to Delete user',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          getUserInfo();
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
          showToastNotification({
            message: 'Successfully Deleted user',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
    const body = {
      user_id: userData.user_id,
    }
    ApiService.getClearoutUserStatuses(body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          console.log('failed')
        } else {
          (response.status === Constants.CO_API.SUCCESS)
          const lists = response.data.lists || [];
          setState(prevState => ({ ...prevState, userRunningFiles: lists }));
        }
      })
      .catch((error) => {
        console.log(error)
      })
  };

  const handleCloseModal = () => {
    setState(initialState)
  }

  return (
    <>
      <p
        onClick={handleShowModal}
        className='text-danger m-0'
      >
        Delete User
      </p>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Are You Sure want to Delete {userData.name || userData.email}&apos;s account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          {state.userRunningFiles.length > 0 ?
            <div
              className="col-md-12 text-danger"
            >
              <label>User is having {state.userRunningFiles.length} running list currently</label>
              <ol>
                {state.userRunningFiles.map((map, key) => {
                  return <li key={key}>{map.details.name}&nbsp;({map.user_details[0].email})({map.action_inprogress.action})</li>
                })}
              </ol>
            </div>
            :
            null
          }
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-2'>
                  <Form.Label className='w-100 py-2'>Reason: </Form.Label>
                </div>
                <div className='col-md-10'>
                  <Form.Control
                    type='text'
                    value={state.reason}
                    onChange={handleReasonChange}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress}
          >
            Yes!!! Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteUserModel;
