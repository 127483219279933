import React from 'react';
import _ from 'lodash';

import * as IndividualUserConstants from './helpers/constant';
import * as UserInfoFormatters from './helpers/user_info_formatters';

import './../../static/css/profile.css'


const ExtraUserInfo = ({ userData, client, isChecked, handleToggleChange, additionalData, getUserInfo }) => {

  const getExtraUserInfo = () => {
    const userInfo = IndividualUserConstants.EXTRA_USER_INFO[client];

    return (
      <>
        {userInfo.map((opts, idx) => {
          const accessorValue = _.get(userData, opts.accessorKey);
          return (
            <div 
              key={idx}
              className='col-md-2'
            >
              <span>{opts.label}</span>
              <p>
                {UserInfoFormatters[opts.formatter]({
                  client,
                  value: accessorValue,
                  userData: userData,
                  additionalData: additionalData,
                  isChecked: isChecked,
                  handleToggleChange: handleToggleChange,
                  getUserInfo: getUserInfo
                })}
              </p>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className='row'>
      {getExtraUserInfo()}
    </div>
  );
};

export default ExtraUserInfo;
