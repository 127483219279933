import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ApiService from '../../../../helpers/apiservice';
import LineChart from '../../../widgets/linechart';
import FiltersComponent from './../../../widgets/filters';
import * as Helper from '../../../../helpers/helper';
import * as Constants from '../../../../helpers/constants';
import * as FiltersConstnats from './../../../widgets/filters/constants'

import '../../components/analytics.css'
import 'react-circular-progressbar/dist/styles.css';

const EmailVerifier = ({ client = 'clearout' }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const co_user_id = queryParams.get('user_id');
  const initialstate = {
    filters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      }
    },
  }

  const [emailData, setEmailData] = useState({
    emailFound: 0,
    emailPercentage: 0,
    confidenceLevel: 0,
  });

  const [lineChartData, setLineChartData] = useState({
    labels: [],
    breakdown: [],
    values: [],
  });

  const [state, setState] = useState(initialstate)
  useEffect(() => {
    const filter = {
      org_id: null,
      date_range: state.filters.date_range?.value ? state.filters.date_range?.value : null,
      member_id: null,
      user_id: co_user_id,
    };
    const queryParams = {
      user_id: co_user_id,
      filters: JSON.stringify(filter),
    };
    ApiService.emailFinderReports(queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          const stats = response.data.user_stats[0].stats;

          // Calculate percentage
          const total_found = stats.reduce((total, stat) => total + stat.found, 0);
          const total_request = stats.reduce((total, stat) => total + stat.total, 0);
          const percentage = (total_found === 0 || total_request === 0) ? 0 : Math.round(total_found / total_request * 100);


          // Calculate confidence score
          const total_confidence_score = stats.reduce((total, stat) => total + stat.confidence_score, 0);
          let score = (total_found === 0 || total_confidence_score === 0) ? 0 : total_confidence_score / total_found;
          const conf_score = score > 0 ? Math.round(score) : 0;

          // Update state with the total
          setEmailData({
            emailFound: stats.reduce((total, stat) => total + stat.found, 0),
            emailPercentage: percentage,
            confidenceLevel: conf_score,
          });

          // Update LineChart data
          setLineChartData({
            labels: stats.map((stat) => stat.date),
            breakdown: response.data.breakdown,
            values: [
              {
                label: 'Email Found',
                data: stats.map((stat) => stat.found),
              },
              {
                label: 'Email Not Found',
                data: stats.map((stat) => stat.not_found),
              },
              {
                label: 'Total Request',
                data: stats.map((stat) => stat.total),
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [co_user_id, state.filters]);


  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }

  const getConfidentDetail = (conf_score) => {
    let conf_level = Helper.calculateConfidenceLevelTagFromScore(conf_score)
    // conf_level = conf_level == '-' ? '-' : conf_level
    let color_string = Helper.getColorTagFromConfidenceLevel(conf_level)
    return (
      <span
        ref={el => {
          if (el) {
            el.style.setProperty('color', color_string, 'important');
            el.style.setProperty('font-size', '16px', 'important');
          }
        }}
      >{conf_level}
      </span>
    )
  }

  return (
    <>
      <FiltersComponent
        filterState={state.filters}
        onSetFilters={onSetFilter}
        filters={FiltersConstnats.ANALYTICS[client].filters}
        filtersToInclude={FiltersConstnats.FILTERS_TO_INCLUDE[client].ANALYTICS}
        className='analytics-filter'
        resetFilters={FiltersConstnats.ANALYTICS[client].onResetFilters}
      />
      <div className="overview-container border rounded p-3">
        <div className="row">
          {/* Left Box */}
          <div className="col text-center">
            <div className='total-verified fw-bold fs-5'>
              <p>Email Found</p>
              <p className='text-orange'>{emailData.emailFound}</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Email Found Percentage</p>
              <p className='text-orange'>{emailData.emailPercentage}%</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Confidence Level</p>
              {getConfidentDetail(emailData.confidenceLevel)}
            </div>
          </div>
          {/* Separator */}
          <div className="col-1 border-start"></div>
          {/* Right Box */}
          <div className="col-8 text-center analytics-chart">
            <LineChart
              graphData={lineChartData}
              width={300}
              height={250}
              position="bottom"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerifier;
