import React from 'react';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames';

import ClipboardCopy from '../../widgets/clipboard_copy';
import UpdateEmailModal from './../update_email';
import AddDailyLimitModal from '../add_daily_limit';
import UpdateDailyLimitModal from '../update_daily_limit'
import RemoveDailyLimit from '../remove_daily_limit'
import AllowedDailyLimitModal from '../allowed_daily_limit'
import AddBonusModel from '../add_bonus';
import ResetBonusModel from '../reset_bonus';
import UpdateMemberStatus from '../update_member_status';
import UpdateTimezoneModal from '../update_timezone';
import UpdateRateLimitModal from '../update_api_rate_limit';
import RemoveRateLimitModel from '../remove_api_rate_limit';
import * as Helpers from '../../../helpers/helper'
import * as IndividualUserConstants from '../helpers/constant'
import ClickToCopy from '../../widgets/copy_onclick';
import * as Constants from '../../../helpers/constants'


import 'react-toggle/style.css';

export const emailFormatter = ({ client, value, userData, user_id, getUserInfo }) => {
  return (
    <>
      <div>
        <ClickToCopy text={value} />
      </div>
      <div className="px-2">
        {_.includes(IndividualUserConstants.BASIC_INFO[client].SHOW_BUTTONS, 'edit_email') ? (
          <UpdateEmailModal
            client={client}
            couserid={user_id}
            getUserInfo={getUserInfo}
            data={userData}
          />
        ) : null}
      </div>
    </>
  )
}

export const phoneFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </>
  )
}

export const remainingCreditsFormatter = ({ client, value, userData, getUserInfo }) => {
  return (
    <div className="d-flex gap-2 remaining-credits">
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
      {userData.user_type === 'individual' ? (
        <AddBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          email={userData.email}
          userData={userData}
        />
      ) : null}
      {userData.user_type === 'individual' ? (
        <ResetBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          userData={userData}
        />
      ) : null}
    </div>
  )
}

export const jobTitleFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.job_title
        ? Helpers.capitalizeFirstLetter(additionalData.job_title)
        : '-'}
    </>
  )
}

export const industryFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.industry
        ? Helpers.capitalizeFirstLetter(additionalData.industry)
        : '-'}
    </>
  )
}

export const signupFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.signup_source && additionalData.signup_method
        ? `${Helpers.capitalizeFirstLetter(additionalData.signup_source)} / ${Helpers.capitalizeFirstLetter(additionalData.signup_method)}`
        : 'Direct / Email'}
    </>
  )
}

export const companyNameFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.organization_name
        ? Helpers.capitalizeFirstLetter(additionalData.organization_name)
        : '-'}
    </>
  )
}

export const userIdFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </>
  )
}
export const orgIdFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </>
  )
}

export const activatedOnFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={moment(value).format('DD-MMM-YYYY @ h:mm:ss A')} />
      ) : 'Yet to Activate'}
    </>
  )
}

export const memberStatusFormatter = ({ value, userData, user_id, getUserInfo }) => {
  return (
    <>
      <div className={userData.account_status === 'live' ? 'text-success' : 'text-danger'}>
        {Helpers.capitalizeFirstLetter(value) ?? '-'}
      </div>
      <div>
        {userData.user_type == 'organization' && userData.user_role !== 'owner' ? (
          <UpdateMemberStatus
            couserid={user_id}
            getUserInfo={getUserInfo}
            userData={userData}
          />
        ) : null}
      </div>
    </>
  )
}

export const accountStatusFormatter = ({ value, userData }) => {
  return (
    <div className={userData.status === 'active' ? 'text-success' : 'text-danger'}>
      {Helpers.capitalizeFirstLetter(value) ?? '-'}
    </div>
  )
}

export const radarFormatter = ({ userData, isChecked, handleToggleChange }) => {
  return (
    <>
      {userData && userData.user_role === 'owner' ? (
        <Toggle
          checked={isChecked}
          onChange={handleToggleChange}
          disabled={
            userData.status === 'active' &&
              userData.account_status === 'live' &&
              userData.activated_on
              ? false
              : true
          }
        />
      ) : (
        '-'
      )}
    </>
  )
}

export const timezoneFormatter = ({ value, userData, user_id, getUserInfo, client }) => {
  return (
    <>
      <div>
        {value ?? '-'}
      </div>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='mx-2'>
            <UpdateTimezoneModal
              couserid={user_id}
              getUserInfo={getUserInfo}
              data={userData}
              client={client}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export const totalAmountPaidFormatter = ({ value }) => {
  return (
    <>
      {value ? Number(value).toLocaleString() : '-'}
    </>
  )
}

export const lastPurchasedOnFormatter = ({ value }) => {
  return (
    <>
      {value ? moment(value).format('DD-MMM-YYYY @ h:mm:ss A') : '-'}
    </>
  )
}

export const totalPaymentCountFormatter = ({ value }) => {
  return (
    <>
      {value ? value : '-'}
    </>
  )
}

// credits and limits tab sections formatters

export const totalRemainingCreditsFormatter = ({ value }) => {
  return (
    <>
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
    </>
  )
}

export const payAsYouGoCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const subscriptionCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const recurCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const apiRateLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='d-flex'>
            <div className='mx-2'>
              <UpdateRateLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
            <div>
              <RemoveRateLimitModel
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantEmailVerifyFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const instantEmailFinderFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const dailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )

}

export const remainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex'>
          <p>{(userData.credits.available_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' && userData.limits.allowed_daily_limit ? (
            <div className='mx-2'>
              <UpdateDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export const allowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex'>
          <p>{(userData.limits.allowed_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' ? (
            <div className='mx-2'>
              <AllowedDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

// cop formatterrs

export const copDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_verify_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_verify_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )
}

export const copRemainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.credits.available_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <UpdateDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const copAllowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.limits.allowed_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <AllowedDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantPhoneNumberValidatorFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

// receipt table formatter

export const receiptCreditFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const receiptBillingFormatter = ({ value }) => {
  return (
    <>
      {value ?
        (value === 30 ? 'Monthly' : 'Annually') : '-'}
    </>
  )
}

export const receiptPaymentFormatter = ({ value }) => {
  return (
    <>
      {value}
    </>
  )
}

export const receiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const receiptAmountFormatter = ({ rowData }) => {
  const getAmount = (invoice) => {
    let amount, convertedAmount;

    amount = invoice.amount;
    if (invoice && invoice.currency_exchange_info) {
      convertedAmount = (Math.floor(invoice.currency_exchange_info.final_price * 100) / 100);
      convertedAmount = `${invoice.currency_exchange_info.currency_symbol}${convertedAmount.toLocaleString('en-IN')}`;
    }
    return (
      <td>
        {Number(amount).toLocaleString()} <br />
        {convertedAmount ? convertedAmount : null}
      </td>
    );
  };
  return (
    <div>
      {getAmount(rowData)}
    </div>
  )
}

export const receiptCouponFormatter = ({ rowData }) => {
  const getCoupon = (invoice) => {
    let coupon = '-'
    if (invoice && invoice.discount) {
      coupon =
        <>
          <Link to={'/dashboard/clearout/coupons/' + invoice.discount.coupon}>
            {invoice.discount.coupon}
          </Link>
          &nbsp;(<small>${invoice.discount.discount_price}</small>)
        </>
    }
    return <td>{coupon}</td>
  }

  return (
    <>
      {getCoupon(rowData)}
    </>
  )
}

export const receiptPurchasedFormatter = ({ value }) => {
  return (
    <small>
      {moment(value).format('DD MMM YY, hh:mm:ss A z')}
    </small>
  )
}

export const receiptTypeFormatter = ({ value }) => {
  return (
    <>
      {Helpers.capitalizeFirstLetter(value)}
    </>
  )
}

export const receiptDescriptionFormatter = ({ value }) => {
  return (
    <>
      {value}
    </>
  )
}

export const receiptPlanIdFormatter = ({ value }) => {
  return (
    <>
      <b><small>{value ? value : '-'}</small></b>
    </>
  )
}

export const receiptPayeeInfoFormatter = ({ value, rowData }) => {
  const getPayeeInfo = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.payer.name.given_name + ' ' + invoice.payment_details.result.payer.name.surname}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.payer.email_address}&nbsp; &nbsp;
            <ClipboardCopy textToCopy={invoice.payment_details.result.payer.email_address} />
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeInfo(rowData) : '-'}
    </>
  )
}

export const receiptPayeeAddressFormatter = ({ value, rowData }) => {
  const getPayeeAddress = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.purchase_units[0].shipping.address.address_line_1}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_1 + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_2}
          </span>
          <span>
            {invoice.payment_details.result.purchase_units[0].shipping.address.country_code + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.postal_code}
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeAddress(rowData) : '-'}
    </>
  )
}

export const invoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData }) => {
  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          APP_API_URL + IndividualUserConstants.CO_INVOICE_DOWNLOAD_ENDPOINT +
          'invoice_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData.user_id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          APP_API_URL + IndividualUserConstants.CO_RECEIPT_DOWNLOAD_ENDPOINT +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData.user_id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}

//cop receipt table formatter
export const copReceiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const copReceiptAmountFormatter = ({ rowData }) => {
  const getCopAmount = (invoice) => {
    let amount, convertedAmount

    amount = invoice.amount
    if (invoice && invoice.currency_exchange_info && Object.keys(invoice.currency_exchange_info).length) {
      convertedAmount = (Math.floor(invoice.currency_exchange_info.final_price * 100) / 100)
      convertedAmount = `${invoice.currency_exchange_info.currency_symbol}${convertedAmount.toLocaleString('en-IN')}`
    }
    return (
      <td>
        {Number(amount).toLocaleString()} < br />
        {convertedAmount ? convertedAmount : null}
      </td>
    )
  }
  return (
    <>
      {getCopAmount(rowData)}
    </>
  )
}

export const copReceiptCouponFormatter = ({ rowData }) => {
  const getCopCoupon = (invoice) => {
    let coupon = '-'

    if (invoice && invoice.discount) {
      coupon =
        <>
          <Link to={'/dashboard/cop/coupons/' + invoice.discount.coupon}>
            {invoice.discount.coupon}
          </Link>
          &nbsp;(<small>${invoice.discount.discount_price}</small>)
        </>
    }
    return <td>{coupon}</td>
  }

  return (
    <>
      {getCopCoupon(rowData)}
    </>
  )
}


export const copInvoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, userName, id }) => {

  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_INVOICE_DOWNLOAD_ENDPOINT +
          'invoice_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_RECEIPT_DOWNLOAD_ENDPOINT +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}




















