import React from 'react'
import _ from 'lodash'
import Dropdown from 'react-bootstrap/Dropdown';

import * as IndividualUserConstants from './helpers/constant'
import LogoutSessionModal from './logout_sessions'
import BlockUserModel from './block_user';
import UnBlockUserModel from './unblock_user';
import DeleteUserModel from './delete_user';
import SignUpLink from './signup_link';
import ForgotPasswordLink from './password_link';
import CopViewStats from './cop_view_stats';

import './../../static/css/profile.css'


const ActionButtons = ({ client, userData, getUserInfo }) => {
  return (
    <>
      <Dropdown className='text-end'>
        <Dropdown.Toggle
          className='action-button'
          variant="primary"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className='px-2 action-dropdown' >
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'logout_sessions') &&
            userData.status !== 'inactive' ?
            <Dropdown.Item>
              <LogoutSessionModal
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'block_user') &&
            userData.status === 'active' ?
            <Dropdown.Item>
              <BlockUserModel
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'unblock_user') &&
            userData.status === 'inactive' && userData.account_status === 'live' ?
            <Dropdown.Item>
              <UnBlockUserModel
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'forgot_password_link') &&
            userData.status !== 'inactive' ?
            <Dropdown.Item>
              <ForgotPasswordLink
                userData={userData}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'signup_link') &&
            userData && !userData.activated_on && userData.status !== 'inactive' ?
            <Dropdown.Item>
              <SignUpLink
                userData={userData}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'cop_view_stats') &&
            <Dropdown.Item>
              <CopViewStats
                userData={userData}
              />
            </Dropdown.Item>
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'delete_user') &&
            userData.user_role === 'owner' && userData.status === 'active' ?
            <Dropdown.Item>
              <hr className='m-0' />
              <DeleteUserModel
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default ActionButtons
