import React from 'react';
import * as Constants from './constants'
import axios from 'axios';
import moment from 'moment';

export const Header = {
  'Content-Type': 'application/json'
}

const getAccessToken = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.COCRM_USER].jwtToken) {
    return user[Constants.COCRM_USER].jwtToken
  } else {
    console.warn('No token found')
    return null
  }
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}
export function getPaidTag(user) {
  let isPaid = false
  if (user.tags && user.tags.length > 0) {
    let paidTag = user.tags.find((tag) => tag.type === 'paid')
    isPaid = paidTag.is_paid
  }
  return isPaid
}

export function formatTime(time, format) {
  return moment.parseZone(time).format(format)
}
export function formatDate(date, format) {
  return moment(date).format(format);
}

export function isNumber(value) {
  let regex = /^[0-9]+$/;
  return regex.test(value)
}

export const getAuthHeader = () => {
  let header = Header
  if (getAccessToken()) {
    header = Object.assign({}, Header, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Crm-App-Version': APP_VERSION
    })
  }
  return header
}

export function ajax(url, method, data = null, appendBaseUrl = true, signal = null, appendAuth = true, withCredentials = false) {
  return new Promise((resolve, reject) => {
    let apiUrl = url
    if (appendBaseUrl)
      apiUrl = APP_API_URL + url

    const headers = getAuthHeader()

    var options = {}
    if (appendAuth) {
      options = {
        url: apiUrl,
        method: method,
        headers
      }
    } else {
      options = {
        url: apiUrl,
        method: method,
      }
    }

    if (data) {
      options.data = data
    }

    if (signal) {
      options.signal = signal
    }

    if (withCredentials) {
      options.withCredentials = true
    }

    axios(options)
      .then(res => {
        switch (res.status) {
          case 500:
            console.error('Some server error')
            reject(res)
            break
          case 401:
            console.error('Unauthorized')
            reject(res)
            break
          case 400:
            console.warn('Bad Request')
            reject(res)
            break
          case 418:
            // versionupdate().openModal(res)
            break
          default:
            break
        }
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })

}

export function getRecurTable(recur) {
  let table =
    <table>
      <tbody>
        <tr>
          <td>

            <b className={'text-success'} >
              {' '}{recur.credits}/{recur.type}
            </b>
          </td>
        </tr>
        <tr>
          <td>
            {formatTime(recur.recur_credit_reset_date)}
          </td>
        </tr>
      </tbody>
    </table>
  return table
}
//linechart
export function pickChartColor(index) {
  if (index > Constants.GRAPH_COLORS.length - 1) {
    index = index % (Constants.GRAPH_COLORS.length - 1)
  }
  let color = Constants.GRAPH_COLORS[index]
  return color
}

export function pickAreaChartColor(index) {
  if (index > Constants.GRAPH_AREA_COLORS.length - 1) {
    index = index % (Constants.GRAPH_AREA_COLORS.length - 1)
  }
  let color = Constants.GRAPH_AREA_COLORS[index]
  return color
}

export function getSubsTable(subs) {
  return (
    <div>
      <p className={`subscription-plan fw-bold ${subs.status === 'active' ? 'text-success' : 'text-danger'}`}>{subs.plan_name}</p>
      <p>${Number(subs.plan_price).toLocaleString()} / {subs.plan_type}</p>
      <p>{Number(subs.credits).toLocaleString()} Credits</p>
    </div>
  );
}

export function calculateConfidenceLevelTagFromScore(confidence_score) {
  let confidenceLevel
  // console.log(confidence_score)
  if (confidence_score) {
    if (confidence_score > 0 && confidence_score <= 30) confidenceLevel = 'Very Low'
    else if (confidence_score > 30 && confidence_score <= 60) confidenceLevel = 'Low'
    // else if (confidence_level > 60 && confidence_level <= 60) confidence_text = "Medium"
    else if (confidence_score > 60 && confidence_score <= 80) confidenceLevel = 'Moderate'
    else if (confidence_score > 80) confidenceLevel = 'High'
    else confidenceLevel = '-'
  } else { // For backward compatibility sakes
    confidenceLevel = '-'
  }

  return confidenceLevel
}

export function getColorTagFromConfidenceLevel(confidence_level) {
  let color

  switch (confidence_level) {
    case 'Very Low':
      color = 'red'
      break
    case 'Low':
      color = 'coral'
      break
    case 'Moderate':
      color = 'blue'
      break
    case 'High':
      color = 'green'
      break
    default:
      color = 'orange'
  }
  return color
}

export function getChannelSource(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.source ? (
          <p className="text-capitalize">
            {source.source}
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function getChannelSourceCampaign(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.campaign ? (
          <p>
            {source.campaign}
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function getChannelQuery(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.url_params ? (
          <p>
            <span className="channel_source">{source.url_params}</span>
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function removeUnderscore(inputString) {
  return inputString.replace(/_/g, ' ');
}


