import React from 'react';

export const BASIC_USER_INFO = {
  clearout: {
    LEFT_TABLE: [
      {
        label: 'Job Title',
        // accessorKey: 'job_title', this is additional userdata 
        formatter: 'jobTitleFormatter',
      },
      {
        label: 'Industry',
        // accessorKey: 'industry',  this is additional userdata 
        formatter: 'industryFormatter',
      },
      {
        label: 'Company Name',
        // accessorKey: 'industry',  this is additional userdata 
        formatter: 'companyNameFormatter',
      }
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'emailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'phone_number',
        formatter: 'phoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  clearoutphone: {
    LEFT_TABLE: [
      {
        label: 'Job Title',
        // accessorKey: 'job_title', this is additional userdata 
        formatter: 'jobTitleFormatter',
      },
      {
        label: 'Industry',
        // accessorKey: 'industry',  this is additional userdata 
        formatter: 'industryFormatter',
      },
      {
        label: 'Company Name',
        // accessorKey: 'industry',  this is additional userdata 
        formatter: 'companyNameFormatter',
      }
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'emailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'phone_number',
        formatter: 'phoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      }
    ]
  },
};

export const ACTION_BUTTONS = {
  clearout: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  clearoutphone: ['logout_sessions', 'block_user', 'unblock_user', 'signup_link', 'forgot_password_link', 'cop_view_stats','delete_user']
}

export const QUICK_LINKS = {
  clearout: ['user_activities', 'matomo_activities', 'email_verifier_lists', 'email_finder_lists', 'prospect_lists'],
  clearoutphone: ['user_activities', 'matomo_activities', 'clearout_phone_lists']
}

export const TAB_SECTION = {
  clearout: ['credits_limits', 'plans', 'subscriptions', 'invoices_receipts', 'analytics'],
  clearoutphone: ['credits_limits', 'invoices_receipts']
}

export const CREDITS_LIMITS_TAB = {
  clearout: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  clearoutphone: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Phone Number Validator',
        accessorKey: 'limits.instant_phonenumber_validate_limit.maxRequests',
        formatter: 'instantPhoneNumberValidatorFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'copDailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'copRemainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'copAllowedDailyLimitFormatter',
      },
    ]
  },
};

export const INVOICE_AND_RECEIPT_TAB = {
  clearout: {
    SHOW_BUTTONS: ['generate_receipt'],
  },
  clearoutphone: {
    SHOW_BUTTONS: ['generate_receipt'],
  },
}

export const BASIC_INFO = {
  clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  clearoutphone: {
    SHOW_BUTTONS: ['edit_email'],
  },
}

export const RECEIPT_TABLE_COLUMNS = {
  clearout: [
    {
      header: 'Credits',
      accessorKey: 'credit',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'subscription_details.interval_in_days',
      formatter: 'receiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Unit Price</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'unit_price',
      formatter: 'receiptUnitPriceFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      // accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      // accessorKey: 'discount',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'subscription_details.plan_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payment_details',
      formatter: 'receiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payment_details',
      formatter: 'receiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  clearoutphone: [
    {
      header: 'Credit Value',
      accessorKey: 'credit',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'subscription_details.interval_in_days',
      formatter: 'receiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Unit Price</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'unit_price',
      formatter: 'copReceiptUnitPriceFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      // accessorKey: 'amount',
      formatter: 'copReceiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      // accessorKey: 'amount',
      formatter: 'copReceiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'subscription_details.plan_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payment_details',
      formatter: 'receiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payment_details',
      formatter: 'receiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'copInvoiceAndReceiptDownloadFormatter'
    }

  ]
}

export const EXTRA_USER_INFO = {
  clearout: [
    {
      label: 'Member Status',
      accessorKey: 'account_status',
      formatter: 'memberStatusFormatter',
    },
    {
      label: 'Account Status',
      accessorKey: 'status',
      formatter: 'accountStatusFormatter',
    },
    {
      label: 'Signup Type',
      // accessorKey: 'industry', this is additional userdata 
      formatter: 'signupFormatter',
    },
    {
      label: 'Activated On',
      accessorKey: 'activated_on',
      formatter: 'activatedOnFormatter',
    },
    {
      label: 'Id',
      accessorKey: 'user_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Org Id',
      accessorKey: 'org_id',
      formatter: 'orgIdFormatter',
    },
    {
      label: 'Use Radar',
      // accessorKey: 'status',
      formatter: 'radarFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'remainingCreditsFormatter',
    },
    {
      label: 'Total Amount Paid (In $)',
      accessorKey: 'tags[0].total',
      formatter: 'totalAmountPaidFormatter',
    },
    {
      label: 'Last Purchased On',
      accessorKey: 'tags[0].last_purchased_on',
      formatter: 'lastPurchasedOnFormatter',
    },
    {
      label: 'Total Payment Count',
      accessorKey: 'tags[0].count',
      formatter: 'totalPaymentCountFormatter',
    },

  ],
  clearoutphone: [
    {
      label: 'Member Status',
      accessorKey: 'account_status',
      formatter: 'memberStatusFormatter',
    },
    {
      label: 'Account Status',
      accessorKey: 'status',
      formatter: 'accountStatusFormatter',
    },
    {
      label: 'Activated On',
      accessorKey: 'activated_on',
      formatter: 'activatedOnFormatter',
    },
    {
      label: 'Id',
      accessorKey: 'user_id',
      formatter: 'userIdFormatter',
    },
    // {
    //   label: 'Org Id',
    //   accessorKey: 'org_id',
    //   formatter: 'orgIdFormatter',
    // },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'remainingCreditsFormatter',
    },
    {
      label: 'Total Amount Paid (In $)',
      accessorKey: 'tags[0].total',
      formatter: 'totalAmountPaidFormatter',
    },
    {
      label: 'Last Purchased On',
      accessorKey: 'tags[0].last_purchased_on',
      formatter: 'lastPurchasedOnFormatter',
    },
    {
      label: 'Total Payment Count',
      accessorKey: 'tags[0].count',
      formatter: 'totalPaymentCountFormatter',
    },


  ]
}

export const CO_INVOICE_DOWNLOAD_ENDPOINT = 'api/v1/clearout/download/invoice?'
export const CO_RECEIPT_DOWNLOAD_ENDPOINT = 'api/v1/clearout/download/receipt?'

export const COP_INVOICE_DOWNLOAD_ENDPOINT = 'v1/crm/download/invoice?'
export const COP_RECEIPT_DOWNLOAD_ENDPOINT = 'v1/crm/download/receipt?'